import React from 'react'
import { Box, Typography, Button, Avatar, Card, CardContent, Divider } from '@mui/material'

type Params = {
  onFinishInvitation: any
  foundSubscriptions: any
}

const ContactReviewDetail: React.FC<Params> = ({ foundSubscriptions = {}, onFinishInvitation = null }) => {
  const handleFinishInvitation = (subscription) => {
    onFinishInvitation(subscription)
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1 }}>
      {foundSubscriptions.data &&
        foundSubscriptions.data.data &&
        foundSubscriptions.data.data.map((subscription) => (
          <Box component="form" sx={{ flexGrow: 1 }}>
            <Card sx={{ boxShadow: 2, borderRadius: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Avatar
                    alt={subscription.owners.data[0].name}
                    src={subscription.owners.data[0].avatar}
                    sx={{
                      width: 48,
                      height: 48,
                      mr: 1,
                      backgroundColor: `#${subscription.owners.data[0].avatar_colour}`
                    }}
                  />
                  <Box>
                    <Typography variant="body1" fontWeight="bold" color="black">
                      {subscription.owners.data[0].name}
                    </Typography>
                    <Typography variant="subtitle2" color="grey">
                      {subscription.owners.data[0].email}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                  <Typography variant="body1" fontWeight="bold" color="black">
                    {subscription.name}
                  </Typography>
                  <Typography variant="subtitle2" color="grey">
                    {subscription.industry && subscription.industry.data ? subscription.industry.data.name : 'Other'}
                  </Typography>
                </Box>
              </CardContent>

              {/* Buttons */}
              <Box display="flex" justifyContent="space-between" p={1}>
                <Button variant="contained" color="primary" onClick={() => handleFinishInvitation(subscription)}>
                  Send Invite to {subscription.owners.data[0].name} at {subscription.name}
                </Button>
              </Box>
            </Card>
          </Box>
        ))}
    </Box>
  )
}

export default ContactReviewDetail
