import { HomeOutlined } from '@mui/icons-material'
import { Box, Breadcrumbs, Typography } from '@mui/material'
import { PartnerIcon, AddPartnerIcon } from '@papertrail/styleguide'
import React from 'react'

const CustomBreadCrumbs = () => {
  return (
    <Box
      px={2}
      height={'54px'}
      flexShrink={0}
      sx={{ backgroundColor: 'white' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}>
      <Breadcrumbs separator={'>'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'8px'}>
          <HomeOutlined />
          <Typography>Home</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'8px'}>
          <PartnerIcon color={'#00000099'} />
          <Typography>PartnerHub</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'8px'}>
          <AddPartnerIcon />
          <Typography>Partners</Typography>
        </Box>
      </Breadcrumbs>
    </Box>
  )
}

export default CustomBreadCrumbs
