import { Subscription } from 'src/types'
import { PayloadAction, Reducer, createSlice } from '@reduxjs/toolkit'

export interface InvitationState {
  subscription: Subscription | null
}

export const initialState: InvitationState = {
  subscription: null
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action.payload
    }
  }
})

export const { resetState, setSubscription } = globalSlice.actions

const globalReducer: Reducer<InvitationState> = globalSlice.reducer

export default globalReducer
