import React from 'react'
import { Button, Grid, Typography, Card, CardContent, Box, Avatar } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateSamePermissions } from '../../../../state/slices/invitation'

const MultipleWorkspaces = () => {
  const dispatch = useDispatch()
  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={3}>
        {/* Apply Same Permissions to All Workspaces */}
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                <Box flex="0.12">
                  <Avatar alt="Avatar" variant="rounded" sx={{ backgroundColor: '#007AFF' }}>
                    =
                  </Avatar>
                </Box>
                <Box flex="1">
                  <Typography variant="h6" component="div">
                    Apply the Same Permissions to All Workspaces
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    We’ll set the permissions once and apply them to all Workspaces.
                  </Typography>
                </Box>
              </Box>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '16px' }}
                onClick={() => dispatch(updateSamePermissions(true))}>
                Apply Same Permissions
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Set Specific Permissions for Each Workspace */}
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} gap="16px">
                <Box flex="0.12">
                  <Avatar alt="Avatar" variant="rounded" sx={{ backgroundColor: '#007AFF' }}>
                    ≠
                  </Avatar>
                </Box>
                <Box flex="1">
                  <Typography variant="h6" component="div">
                    Set Specific Permissions for Each Workspace
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    We’ll review each Workspace individually to set specific permissions.
                  </Typography>
                </Box>
              </Box>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: '16px' }}
                onClick={() => dispatch(updateSamePermissions(false))}>
                Set Specific Permissions
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default MultipleWorkspaces
