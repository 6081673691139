import { Box, Typography } from '@mui/material'
import React from 'react'
import PartnerPermissions from './partnerPermissions'
import { RootState } from 'src/state/store'
import { useSelector } from 'react-redux'

type Props = {
  initial: boolean
}

const review = (props: Props) => {
  const { initial } = props
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)

  return (
    <>
      {initial === true ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          rowGap={2}
          justifyContent={'space-evenly'}
          alignContent={'center'}
          alignItems={'center'}
          padding={'32px 24px 0px 24px'}>
          <Box>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <path
                d="M15.9987 40C18.932 40 21.332 42.4 21.332 45.3333C21.332 48.2667 18.932 50.6667 15.9987 50.6667C13.0654 50.6667 10.6654 48.2667 10.6654 45.3333C10.6654 42.4 13.0654 40 15.9987 40ZM15.9987 34.6667C10.132 34.6667 5.33203 39.4667 5.33203 45.3333C5.33203 51.2 10.132 56 15.9987 56C21.8654 56 26.6654 51.2 26.6654 45.3333C26.6654 39.4667 21.8654 34.6667 15.9987 34.6667ZM31.9987 13.3333C34.932 13.3333 37.332 15.7333 37.332 18.6667C37.332 21.6 34.932 24 31.9987 24C29.0654 24 26.6654 21.6 26.6654 18.6667C26.6654 15.7333 29.0654 13.3333 31.9987 13.3333ZM31.9987 8C26.132 8 21.332 12.8 21.332 18.6667C21.332 24.5333 26.132 29.3333 31.9987 29.3333C37.8654 29.3333 42.6654 24.5333 42.6654 18.6667C42.6654 12.8 37.8654 8 31.9987 8ZM47.9987 40C50.932 40 53.332 42.4 53.332 45.3333C53.332 48.2667 50.932 50.6667 47.9987 50.6667C45.0654 50.6667 42.6654 48.2667 42.6654 45.3333C42.6654 42.4 45.0654 40 47.9987 40ZM47.9987 34.6667C42.132 34.6667 37.332 39.4667 37.332 45.3333C37.332 51.2 42.132 56 47.9987 56C53.8654 56 58.6654 51.2 58.6654 45.3333C58.6654 39.4667 53.8654 34.6667 47.9987 34.6667Z"
                fill="#007AFF"
              />
            </svg>
          </Box>
          <Box>
            <Typography variant="h5">
              {inviteSupplier.company_name} has provided access to their account(s). Please review their setup.
            </Typography>
          </Box>
        </Box>
      ) : (
        <PartnerPermissions />
      )}
    </>
  )
}

export default review
