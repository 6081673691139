import React from 'react'
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'

const InviteSent = () => {
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      alignContent={'center'}
      paddingTop={2}
      gap={2}>
      <Box>
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
          <path
            d="M32.5928 52.9496C32.1128 53.4296 31.2594 53.5096 30.6994 52.9496C30.2194 52.4696 30.1394 51.6163 30.6994 51.0563L39.7394 42.0163L35.9794 38.2563L26.9394 47.2963C26.4328 47.8296 25.5794 47.8029 25.0461 47.2963C24.4861 46.7363 24.5661 45.8829 25.0461 45.4029L34.0861 36.3629L30.3261 32.6029L21.2861 41.6429C20.8061 42.1229 19.9528 42.2029 19.3928 41.6429C18.8861 41.1363 18.8861 40.2829 19.3928 39.7496L28.4328 30.7096L24.6461 26.9496L15.6061 35.9896C15.1261 36.4696 14.2728 36.5496 13.7128 35.9896C13.2061 35.4563 13.2061 34.6296 13.7128 34.0963L25.3928 22.4163L30.3794 27.3763C32.9128 29.9096 37.2861 29.8829 39.8194 27.3763C42.4328 24.7629 42.4328 20.5496 39.8194 17.9363L34.8594 12.9763L35.6061 12.2296C37.6861 10.1496 41.0728 10.1496 43.1528 12.2296L54.4594 23.5363C56.5394 25.6163 56.5394 29.0029 54.4594 31.0829L32.5928 52.9496ZM58.2194 34.8696C62.3794 30.7096 62.3794 23.9629 58.2194 19.7763L46.9128 8.46961C42.7528 4.30961 36.0061 4.30961 31.8194 8.46961L31.0728 9.21628L30.3261 8.46961C26.1661 4.30961 19.4194 4.30961 15.2328 8.46961L5.79277 17.9096C2.0061 21.6963 1.65944 27.5896 4.7261 31.7496L8.59277 27.8829C7.55277 25.8829 7.89944 23.3496 9.57944 21.6696L19.0194 12.2296C21.0994 10.1496 24.4861 10.1496 26.5661 12.2296L36.0594 21.7229C36.5394 22.2029 36.6194 23.0563 36.0594 23.6163C35.4994 24.1763 34.6461 24.0963 34.1661 23.6163L25.3928 14.8696L9.92611 30.3096C7.31277 32.8963 7.31277 37.1363 9.92611 39.7496C10.9661 40.7896 12.2994 41.4296 13.7128 41.6163C13.8994 43.0029 14.5128 44.3363 15.5794 45.4029C16.6461 46.4696 17.9794 47.0829 19.3661 47.2696C19.5528 48.6563 20.1661 49.9896 21.2328 51.0563C22.2994 52.1229 23.6328 52.7363 25.0194 52.9229C25.2061 54.3629 25.8461 55.6696 26.8861 56.7096C28.1394 57.9629 29.8194 58.6563 31.6061 58.6563C33.3928 58.6563 35.0728 57.9629 36.3261 56.7096L58.2194 34.8696Z"
            fill="#007AFF"
          />
        </svg>
      </Box>
      <Box>
        <Typography variant="h5" textAlign={'center'}>
          You have successfully set up the selected Workspace(s). {inviteSupplier?.host_contact?.company_name} will now
          review your invitation.
        </Typography>
      </Box>
    </Box>
  )
}

export default InviteSent
