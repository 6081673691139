import React, { useEffect } from 'react'
import { useApiGetSubAccounts } from '../../../../hooks'
import WorkspacesGrid from '../../grid/workspaces'
import MultipleWorkspaces from './multipleWorkspaces'
import { useParams } from 'react-router-dom'

type Props = {
  initial: boolean
}

type Params = {
  id: string
}

const Workspaces = (props: Props) => {
  const { initial } = props
  const params = useParams<Params>()
  const subscriptionId = params.id
  const [subAccountsState, loadSubAccounts] = useApiGetSubAccounts()

  useEffect(() => {
    loadSubAccounts({ subscription_id: subscriptionId, role: 'member,owner,employee' })
  }, [])

  return (
    <>
      {subAccountsState.isLoaded && subAccountsState?.data?.data?.length > 0 && (
        <>
          {initial === true ? (
            <WorkspacesGrid data={subAccountsState.data.data}></WorkspacesGrid>
          ) : (
            <MultipleWorkspaces />
          )}
        </>
      )}
    </>
  )
}

export default Workspaces
