import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useState, useMemo, useEffect } from 'react'
import CustomTabs from '../../../molecules/tab'
import CustomTreeView from '../../threeview'
import { RootState } from '../../../../state/store'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentClientWorkspace, updateFolders, updateRootFolderId } from '../../../../state/slices/invitation'
import { useApiGetAccount, useApiGetFolders } from '../../../../hooks'

const WorkspaceReview = () => {
  const dispatch = useDispatch()
  const { inviteSupplier, currentWorkspace, selectedWorkspaces, folders, rootFolderId } = useSelector(
    (state: RootState) => state.invitation
  )
  const TAB_STATES = [
    { value: 'FOLDERS', label: 'Folder Access Permissions' },
    { value: 'PERMISSIONS', label: 'Partner Permissions' }
  ]
  const [accountState, loadAccount] = useApiGetAccount(currentWorkspace?.account_uuid)
  const [folderList, loadFolders] = useApiGetFolders(currentWorkspace?.account_uuid, rootFolderId, folders)
  const handleWorkspaceChange = (event: SelectChangeEvent<any>) => {
    dispatch(
      updateCurrentClientWorkspace(
        inviteSupplier.mappings.find((workspace) => workspace.account_uuid === event.target.value)
      )
    )
  }
  const [currentTab, setCurrentTab] = useState('FOLDERS')

  useEffect(() => {
    if (
      currentWorkspace &&
      currentWorkspace.account_uuid &&
      (!accountState.isLoaded || accountState.data?.data?.id !== currentWorkspace.account_uuid)
    ) {
      loadAccount({ include: 'folder' })
    } else if (!currentWorkspace) {
      dispatch(updateCurrentClientWorkspace(inviteSupplier.mappings[0]))
    }
  }, [currentWorkspace])

  useEffect(() => {
    if (accountState.isLoaded && accountState.data?.data?.id === currentWorkspace.account_uuid) {
      dispatch(updateRootFolderId(accountState.data?.data?.folder?.data?.id))
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [accountState.isLoaded])

  useEffect(() => {
    if (folderList.isLoaded && folderList.data) {
      dispatch(updateFolders(folderList.data))
    }
  }, [folderList.isLoaded])

  const renderFolders = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 2
        }}>
        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          Folders on {accountState.data?.data?.name}
        </Typography>
        <CustomTreeView
          title={`Folders on ${accountState.data?.data?.name}`}
          treeData={folders}
          selectedNodeArray={currentWorkspace?.folders}
          multiSelect={true}
          readOnly={true}
        />
      </Box>
    )
  }, [currentWorkspace, folders, accountState.data])

  return (
    <>
      <Box>
        <Typography variant="body1">Select an account to view its Partner Permissions:</Typography>
      </Box>
      <Box>
        <FormControl fullWidth variant="outlined" required>
          <InputLabel>Workspace</InputLabel>
          <Select
            sx={{ width: '220px' }}
            value={currentWorkspace?.account_uuid || 'null'}
            label="Workspace"
            placeholder="Workspace"
            onChange={handleWorkspaceChange}>
            {inviteSupplier?.mappings.map((option) => (
              <MenuItem key={option.account_uuid} value={option.account_uuid}>
                {selectedWorkspaces.find((workspace) => workspace.id === option.account_uuid).name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box paddingTop="24px">
        <CustomTabs
          options={TAB_STATES}
          onTabChange={(newValue: string) => {
            setCurrentTab(newValue)
          }}
          defaultValue={currentTab}></CustomTabs>
      </Box>
      {currentTab === 'FOLDERS' && currentWorkspace && <Box>{renderFolders}</Box>}
      {currentTab === 'PERMISSIONS' && currentWorkspace && (
        <Box>
          <Box paddingTop="24px">
            <FormControl variant="outlined" required>
              <InputLabel>Folder Permissions</InputLabel>
              <Select
                sx={{ width: '220px' }}
                value={currentWorkspace?.permissions?.folders || 'null'}
                disabled
                label="Folder Permissions"
                placeholder=""
                onChange={null}>
                <MenuItem key={currentWorkspace?.permissions?.folders} value={currentWorkspace?.permissions?.folders}>
                  {currentWorkspace?.permissions?.folders}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box paddingTop="24px">
            <FormControl variant="outlined" required>
              <InputLabel>Record Permissions</InputLabel>
              <Select
                sx={{ width: '220px' }}
                value={currentWorkspace?.permissions?.records || 'null'}
                disabled
                label="Record Permissions"
                placeholder=""
                onChange={null}>
                <MenuItem key={currentWorkspace?.permissions?.records} value={currentWorkspace?.permissions?.records}>
                  {currentWorkspace?.permissions?.records}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box paddingTop="24px">
            <FormControl variant="outlined" required>
              <InputLabel>Inspection Permissions</InputLabel>
              <Select
                sx={{ width: '220px' }}
                value={currentWorkspace?.permissions?.logs || 'null'}
                disabled
                label="Inspection Permissions"
                placeholder=""
                onChange={null}>
                <MenuItem key={currentWorkspace?.permissions?.logs} value={currentWorkspace?.permissions?.logs}>
                  {currentWorkspace?.permissions?.logs}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box paddingTop="24px">
            <FormControl variant="outlined" required>
              <InputLabel>Task Permissions</InputLabel>
              <Select
                sx={{ width: '220px' }}
                value={currentWorkspace?.permissions?.tasks || 'null'}
                disabled
                label="Task Permissions"
                placeholder=""
                onChange={null}>
                <MenuItem key={currentWorkspace?.permissions?.tasks} value={currentWorkspace?.permissions?.tasks}>
                  {currentWorkspace?.permissions?.tasks}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </>
  )
}

export default WorkspaceReview
