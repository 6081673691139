import { Box, DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@papertrail/styleguide'
import React from 'react'
import { useDispatch } from 'react-redux'
import { updateCurrentClientWorkspace, updateMapping, updateSelectedWorkspaces } from '../../../state/slices/invitation'

type Props = {
  data
}

const WorkspacesGrid = (props: Props) => {
  const { data } = props
  const dispatch = useDispatch()

  const formattedData = data.map((item) => {
    const workspace = {
      id: item.id,
      'Workspace Name': item.name
    }
    return workspace
  })

  const renderHeader = (params) => {
    const cellStyle = {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '600',
      color: 'rgba(0, 0, 0, 0.87)'
    }

    return (
      <div data-testid={params.field} style={cellStyle}>
        {params.field}
      </div>
    )
  }

  const renderCell = (params) => {
    const cellStyle = {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.87)'
    }

    return (
      <div data-testid={params.value} style={cellStyle}>
        {params.value}
      </div>
    )
  }

  const getFlexRatio = (field) => {
    switch (field) {
      case 'Workspace Name':
        return 1
    }
  }

  const onRowClicked = (e) => {
    console.log(e)
  }

  const selectedRows = (workspaces) => {
    if (workspaces && workspaces.length) {
      dispatch(
        updateMapping(
          workspaces.map((workspace) => {
            return {
              account_uuid: workspace,
              folders: [],
              permissions: { folders: '', records: '', tasks: '', logs: '' },
              states: []
            }
          })
        )
      )
      const currentWorkspace = {
        account_uuid: workspaces[0],
        folders: [],
        permissions: { folders: '', records: '', tasks: '', logs: '' },
        states: []
      }
      dispatch(updateCurrentClientWorkspace(currentWorkspace))
      dispatch(updateSelectedWorkspaces(data.filter((account) => workspaces.includes(account.id))))
    }
  }

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ padding: '0 10px', fontFamily: 'Poppins' }}>
          <GridToolbarFilterButton />
        </Box>
      </GridToolbarContainer>
    )
  }

  return (
    <Box>
      <DataGrid
        loading={false}
        rowHeight={52}
        initialData={formattedData}
        columns={Object.keys(formattedData[0]).map((field, index) => ({
          field: field,
          flex: getFlexRatio(field),
          headerAlign: 'left',
          align: 'left',
          renderHeader: (params) => renderHeader(params),
          renderCell: (params) => renderCell(params),
          renderEditCell: (params) => renderCell(params)
        }))}
        meta={{
          pagination: { page: 0, pageSize: formattedData.length },
          meta: {
            pagination: {
              total: formattedData.length
            }
          }
        }}
        updatedRowSelection={selectedRows}
        setPaginationModel={null}
        filters={null}
        check={true}
        pinnedColumns={{ left: [] }}
        onRowClicked={onRowClicked}
        customToolbar={customToolbar}
        customSlotProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontFamily: 'Poppins'
                    }
                  }
                }
              }
            },
            sx: {
              '& .MuiTablePagination-selectLabel': {
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.6)',
                fontFamily: 'Poppins'
              },
              '& .MuiTablePagination-select': {
                fontSize: '12px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: 1,
                paddingTop: 1.5
              },
              '& .MuiTablePagination-displayedRows': {
                fontSize: '12px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)'
              }
            }
          }
        }}></DataGrid>
    </Box>
  )
}

export default WorkspacesGrid
