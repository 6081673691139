import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  spacing: 16,
  custom: {
    narrowWidth: '350px'
  },
  palette: {
    primary: {
      main: '#007AFF'
    }
  },
  typography: {
    fontFamily: 'Poppins',
    body1: {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    h5: {
      fontSize: '24px',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h6: {
      fontSize: '20px',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    subtitle2: {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
      letterSpacing: '0.1px'
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          overflow: 'auto',
          padding: 1
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 500,
          color: 'rgba(0, 0, 0, 0.6)',
          letterSpacing: '0.4px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '15px',
          fontWeight: 500,
          letterSpacing: '0.4px'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          letterSpacing: '0.4px'
        }
      }
    }
  }
})

export default theme
