import { Box, Button, Typography } from '@mui/material'
import React, { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/store'
import { useParams } from 'react-router-dom'
import CustomBreadCrumbs from '../../molecules/breadcrumb'
import CustomTabs from '../../molecules/tab'
import DrawerInvitePartners from '../../organisms/drawer/partnerInvitation'
import ManagerGrid from '../../organisms/detail/managerGrid/invite'
import DrawerSupplierReview from '../../organisms/drawer/supplierReview'
import DrawerPartnershipSetup from '../../organisms/drawer/partnershipSetup'
import { updateInviteSupplier, updateWorkspaceId } from '../../../state/slices/invitation'
import { useApiGet } from '@papertrail/web3-utils'
import { setSubscription } from '../../../state/slices/global'
import { ManagerGridTypes } from '../../../types'

type Params = {
  id: string
}

const PageManager = () => {
  const [activeState, setActiveState] = useState<string>(ManagerGridTypes.RECEIVED)

  const [drawerOpened, setDrawerOpened] = useState<boolean>(false)
  const [reviewDrawerOpened, setReviewDrawerOpened] = useState<boolean>(false)
  const [partnershipDrawerOpened, setPartnershipDrawerOpened] = useState<boolean>(false)
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const { subscription } = useSelector((state: RootState) => state.global)
  const params = useParams<Params>()
  const subscriptionId = params?.id
  const dispatch = useDispatch()

  const [SubscriptionState, getSubscription] = useApiGet<any>(`/subscriptions/${subscriptionId}`, (data) => data)

  useEffect(() => {
    if (inviteSupplier.id) {
      if (inviteSupplier.status === 'pending' && inviteSupplier?.consumer_contact?.subscription_id !== subscriptionId) {
        dispatch(updateWorkspaceId(inviteSupplier.host_contact?.account_id))
        dispatch(updateInviteSupplier({ key: 'folder_uuid', value: inviteSupplier.host_contact?.folder_uuid }))
        return setReviewDrawerOpened(true)
      }
      if (inviteSupplier.status === 'sent' && inviteSupplier?.consumer_contact?.subscription_id === subscriptionId) {
        return setPartnershipDrawerOpened(true)
      }
    }
  }, [inviteSupplier.id])

  useEffect(() => {
    if (subscriptionId) getSubscription({})
  }, [subscriptionId])

  const TAB_STATES = useMemo(() => {
    const tabs = [
      { value: ManagerGridTypes.SENT, label: 'Sent', description: 'Invitations Sent' },
      { value: ManagerGridTypes.RECEIVED, label: 'Received', description: 'Invitations Received' },
      { value: ManagerGridTypes.ACTIVE, label: 'Active', description: 'Active Partners' }
      // { value: ManagerGridTypes.INACTIVE, label: 'Inactive', description: 'Inactive Partners' }
    ]

    if (!subscription?.supplier) return tabs.filter((tab) => tab.value !== ManagerGridTypes.SENT)
    return tabs
  }, [subscription])

  const selectedTab = useMemo(() => {
    return TAB_STATES.find((tab) => tab.value === activeState)
  }, [activeState, TAB_STATES])

  useEffect(() => {
    if (SubscriptionState.isLoaded && !SubscriptionState.isError && SubscriptionState.data) {
      dispatch(setSubscription(SubscriptionState.data.data))

      if (SubscriptionState.data.data.supplier) {
        setActiveState(ManagerGridTypes.SENT)
      } else {
        setActiveState(ManagerGridTypes.RECEIVED)
      }
    }
  }, [SubscriptionState])

  const openDrawer = () => {
    setDrawerOpened(true)
  }

  return (
    <>
      <CustomBreadCrumbs />
      <Box
        sx={{
          paddingRight: 2,
          paddingLeft: 2
        }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <CustomTabs
          options={TAB_STATES}
          onTabChange={(value) => setActiveState(value)}
          defaultValue={!!subscription?.supplier ? ManagerGridTypes.SENT : ManagerGridTypes.RECEIVED}
        />
      </Box>
      <Box
        sx={{
          padding: 2
        }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Typography variant="h5">{selectedTab?.description}</Typography>
        {!!subscription?.supplier && (
          <Button
            variant="contained"
            children={'Invite New Partner'}
            data-testid="invite-new-partner"
            onClick={openDrawer}
          />
        )}
      </Box>

      <Box
        sx={{
          paddingRight: 1,
          paddingLeft: 1
        }}>
        {activeState === ManagerGridTypes.SENT && (
          <ManagerGrid filterType={ManagerGridTypes.SENT} drawerOpen={openDrawer} />
        )}
        {activeState === ManagerGridTypes.RECEIVED && (
          <ManagerGrid filterType={ManagerGridTypes.RECEIVED} drawerOpen={openDrawer} />
        )}
        {activeState === ManagerGridTypes.ACTIVE && (
          <ManagerGrid filterType={ManagerGridTypes.ACTIVE} drawerOpen={openDrawer} />
        )}
        {/* {activeState === ManagerGridTypes.INACTIVE && <ManagerGrid filterType={ManagerGridTypes.INACTIVE} />} */}
      </Box>

      <DrawerInvitePartners isOpened={drawerOpened} setIsOpened={setDrawerOpened} />
      <DrawerSupplierReview isOpened={reviewDrawerOpened} setIsOpened={setReviewDrawerOpened} />
      <DrawerPartnershipSetup
        isOpened={partnershipDrawerOpened}
        setIsOpened={setPartnershipDrawerOpened}></DrawerPartnershipSetup>
    </>
  )
}
export default PageManager
