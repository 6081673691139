import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import {
  updateFolders,
  updateInviteSupplier,
  updateRootFolderId,
  updateWorkspaceId,
  updateWorkspaces
} from '../../../../state/slices/invitation'
import { useApiGetAccount, useApiGetFolders, useApiGetSubAccounts } from '../../../../hooks'
import { useDispatch, useSelector } from 'react-redux'

import CustomTreeView from '../../threeview'
import { RootState } from '../../../../state/store'
import { useParams } from 'react-router-dom'

type Params = {
  id: string
}

const FolderMappingDetail = () => {
  const dispatch = useDispatch()
  const { folders, workspaces, workspaceId, rootFolderId, inviteSupplier } = useSelector(
    (state: RootState) => state.invitation
  )
  const params = useParams<Params>()
  const subscriptionId = params.id
  const [subAccountsState, loadSubAccounts] = useApiGetSubAccounts()

  // change to get subscription accounts
  const [accountState, loadAccount] = useApiGetAccount(workspaceId)
  const [folderList, loadFolders] = useApiGetFolders(workspaceId, rootFolderId, folders)

  const handleWorkspaceChange = (event: SelectChangeEvent<any>) => {
    dispatch(updateWorkspaceId(event.target.value))
    dispatch(updateInviteSupplier({ key: 'account_uuid', value: event.target.value }))
  }

  const workspace = useMemo(() => {
    return workspaces?.find((w) => w.value === workspaceId)
  }, [workspaces, workspaceId])

  const renderFolders = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 2
        }}>
        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          Choose where to place the client workspace within the folder structure of {workspace?.label}:
        </Typography>
        <CustomTreeView
          title={`Folders on ${workspace?.label}`}
          treeData={folders}
          selectedNode={
            inviteSupplier.host_contact && inviteSupplier.host_contact.folder_uuid
              ? inviteSupplier.host_contact.folder_uuid
              : inviteSupplier.folder_uuid
          }
          setSelectedNode={(nodeId) => dispatch(updateInviteSupplier({ key: 'folder_uuid', value: nodeId }))}
        />
      </Box>
    )
  }, [folders, workspace, inviteSupplier])

  useEffect(() => {
    if (subscriptionId && !workspaces.length) {
      loadSubAccounts({ subscription_id: subscriptionId, role: 'member,owner,employee' })
    }
  }, [subscriptionId])

  // Fetch the workspace if workspaceId is present
  useEffect(() => {
    if (workspaceId) loadAccount({ include: 'folder', role: 'member,owner,employee' })
  }, [workspaceId])

  // When accounts are loaded, populate the workspaces list
  useEffect(() => {
    if (
      subAccountsState.isLoaded &&
      subAccountsState.data.data &&
      subAccountsState.data.data.length &&
      !workspaces?.length
    ) {
      const workspacesList = subAccountsState.data?.data.map((account) => ({
        label: account.name,
        value: account.id
      }))
      dispatch(updateWorkspaces(workspacesList))
    }
  }, [subAccountsState.isLoaded])

  // Load folders when workspace is fetched and root folder is available
  useEffect(() => {
    if (accountState.isLoaded) {
      dispatch(updateRootFolderId(accountState.data?.data?.folder?.data?.id))
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [accountState, rootFolderId])

  // When folder list is loaded, update the Redux store
  useEffect(() => {
    if (folderList.isLoaded && folderList.data) {
      dispatch(updateFolders(folderList.data))
    }
  }, [folderList])

  // Handle edit case: If folder_uuid and workspaceId already exist, retrieve folders automatically
  useEffect(() => {
    if (inviteSupplier.folder_uuid && workspaceId) {
      // Load folders directly for editing scenario
      if (rootFolderId && folders.length === 0) {
        loadFolders({ include: 'descendants', format: 'tree' })
      }
    }
  }, [inviteSupplier.folder_uuid, workspaceId, rootFolderId])

  return (
    <Box>
      {!!workspaces?.length && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body1">Folder Mapping</Typography>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Workspace</InputLabel>
            <Select
              sx={{ width: '100%' }}
              value={workspaceId}
              label="Workspace"
              placeholder="Workspace"
              onChange={handleWorkspaceChange}>
              {workspaces?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {!!folders.length && renderFolders}
    </Box>
  )
}

export default FolderMappingDetail
