/**
 * INVITATION
 */
export enum InvitationType {
  ClientSupplier = 'client-supplier'
}

export enum InvitationRequesterRole {
  SupplierNew = 'supplier-new',
  SupplierExisting = 'supplier-existing'
}

export interface SupplierContact {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  country_code: string
  subscription_id?: string
  company_name?: string
}

export interface Mapping {
  account_uuid: string
  folders: string[]
  permissions: {
    folders: string
    records: string
    logs: string
    tasks: string
  }
  states: {
    host_state_uuid: string
    consumer_state_uuid: string
  }[]
}

export interface ConsumerAccount {
  id: string
  name: string

  states: {
    data: {
      id: string
      name: string
    }[]
  }
  folder: {
    data: {
      id: string
      name: string
      children: any[]
    }
  }
}

export interface InviteSupplierData {
  id?: string
  type: InvitationType
  as: InvitationRequesterRole
  account_uuid: string
  status: string
  comments: string
  account_name: string
  subscription_uuid?: string
  contact?: SupplierContact
  host_contact?
  consumer_contact?: SupplierContact
  company_name: string
  folder_uuid: string
  industry_uuid: string
  modules: string[]
  mappings?: Mapping[]
  consumerAccounts?: ConsumerAccount[]
  currentWorkspace?: Mapping
}

export interface UpdateMappingStatePayload {
  account_uuid: string // Index of the mapping to update
  newState: Array<{ host_state_uuid: string; consumer_state_uuid: string }> // New state array with key-value pairs
}
