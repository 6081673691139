import React from 'react'
import PageManager from './components/pages/manager'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import store from './state/store'
import { SnackbarProvider } from './components/providers/snackbar'
import theme from './theme'
import { ThemeProvider } from '@mui/material'
import './App.css'

type Props = {
  i18n: any
}

export default function Root(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider>
          <I18nextProvider i18n={props.i18n}>
            <BrowserRouter>
              <Switch>
                <Route path="/partner-hub/:id" component={PageManager} />
              </Switch>
            </BrowserRouter>
          </I18nextProvider>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  )
}
