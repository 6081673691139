/* eslint-disable @typescript-eslint/no-unused-vars */
import type * as rtk from '@reduxjs/toolkit'

import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './slices/rootReducer'

export const store = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export default store
