import React from 'react'
import { Box, Chip } from '@mui/material'

type Props = {
  status: string
  color: string
}

const CustomChip = (props: Props) => {
  const { status, color } = props

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} height={'100%'}>
      <Chip
        sx={{
          backgroundColor: color,
          borderColor: color,
          color: 'white',
          fontSize: '13px',
          textTransform: 'capitalize',
          fontFamily: 'Poppins',
          lineHeight: '18px',
          padding: '4px',
          letterspacing: '0.16px',
          '& .MuiChip-label': {
            padding: '4px'
          }
        }}
        label={status}
        variant="outlined"
      />
    </Box>
  )
}

export default CustomChip
