import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, Checkbox, Radio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TreeItem, TreeView } from '@mui/lab'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TreeNode } from 'src/types'

interface CustomTreeViewProps {
  treeData: TreeNode[]
  title: string
  selectedNode?: string | null // For single node selection
  setSelectedNode?: (nodeId: string | null) => void // Setter for single selection
  selectedNodeArray?: string[] // For multiple node selection
  setSelectedNodeArray?: (nodeIds: string[]) => void // Setter for multiple selection
  readOnly?: boolean
  multiSelect?: boolean
}

const CustomTreeView: React.FC<CustomTreeViewProps> = ({
  treeData,
  title,
  selectedNode,
  setSelectedNode,
  selectedNodeArray = [], // Initialize as empty array if not provided
  setSelectedNodeArray,
  readOnly,
  multiSelect
}) => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])

  useEffect(() => {
    if (multiSelect === true && selectedNodeArray.length > 0) {
      // Use reduce to combine all selected node paths
      const expandedPaths = selectedNodeArray
        .map((nodeId) => findPathToNode(treeData, nodeId))
        .reduce((acc, path) => acc.concat(path), [])
      setExpandedNodes((prevExpanded) => {
        const newExpanded = Array.from(new Set(expandedPaths))
        if (prevExpanded.join() !== newExpanded.join()) {
          return newExpanded
        }
        return prevExpanded // Prevent unnecessary re-render
      })
    } else if (!multiSelect && selectedNode) {
      // Ensure we only expand if the path has actually changed
      const pathToNode = findPathToNode(treeData, selectedNode)
      if (pathToNode.length > 0) {
        setExpandedNodes((prevExpanded) => {
          if (prevExpanded.join() !== pathToNode.join()) {
            return pathToNode
          }
          return prevExpanded // Prevent unnecessary re-render
        })
      }
    }
  }, [selectedNode, selectedNodeArray, multiSelect, treeData])
  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    nodeId: string,
    children: TreeNode[] | undefined
  ) => {
    if (multiSelect === true) {
      if (event.target.checked) {
        // Add parent node and all its children to the selected array
        const nodeIdsToAdd = [nodeId, ...getAllNodeIds(children || [])]
        setSelectedNodeArray?.([...selectedNodeArray, ...nodeIdsToAdd.filter((id) => !selectedNodeArray.includes(id))])
      } else {
        // Remove parent node and all its children from the selected array
        const nodeIdsToRemove = [nodeId, ...getAllNodeIds(children || [])]
        setSelectedNodeArray?.(selectedNodeArray.filter((id) => !nodeIdsToRemove.includes(id)))
      }
    } else {
      setSelectedNode(nodeId) // Handle single-select logic with radio button
    }
  }

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedNodes(nodeIds)
  }

  const handleExpandAll = () => {
    const allNodeIds = getAllNodeIds(treeData)
    setExpandedNodes(allNodeIds)
  }

  const handleCollapseAll = () => {
    setExpandedNodes([])
  }

  const getAllNodeIds = (nodes: TreeNode[]): string[] => {
    let ids: string[] = []
    nodes?.forEach((node) => {
      ids.push(node.id)
      if (node.children) {
        ids = ids.concat(getAllNodeIds(node.children))
      }
    })
    return ids
  }

  const findPathToNode = (nodes: TreeNode[], targetId: string, path: string[] = []): string[] => {
    for (const node of nodes) {
      const newPath = [...path, node.id]
      if (node.id === targetId) {
        return newPath
      }
      if (node.children) {
        const result = findPathToNode(node.children, targetId, newPath)
        if (result.length > 0) {
          return result
        }
      }
    }
    return []
  }

  const renderTree = (nodes: TreeNode[], parentChecked = true) =>
    nodes.length &&
    nodes?.map((node) => {
      const isChecked = selectedNodeArray.includes(node.id) // Check if node is selected in multiSelect mode
      return (
        <TreeItem
          key={node.id}
          nodeId={node.id}
          label={
            multiSelect === true ? (
              // Use Checkbox for multi-select mode
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(event) => handleSelect(event, node.id, node.children)}
                    disabled={!parentChecked || readOnly === true} // Disable child nodes if parent is not selected
                  />
                }
                label={node.name}
                key={node.id}
              />
            ) : (
              // Use Radio for single-select mode
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedNode === node.id}
                    onChange={(event) => handleSelect(event, node.id, node.children)}
                    disabled={readOnly === true}
                  />
                }
                label={node.name}
                key={node.id}
              />
            )
          }>
          {node.children && renderTree(node.children, isChecked)} {/* Pass isChecked to children */}
        </TreeItem>
      )
    })

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Box>
            <Button size="small" onClick={handleExpandAll} sx={{ marginRight: 1 }}>
              Expand All
            </Button>
            <Button size="small" onClick={handleCollapseAll}>
              Collapse All
            </Button>
          </Box>
        }
      />
      <CardContent>
        <TreeView
          expanded={expandedNodes}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeToggle={handleToggle}
          multiSelect={multiSelect || null}>
          {renderTree(treeData)}
        </TreeView>
      </CardContent>
    </Card>
  )
}

export default CustomTreeView
