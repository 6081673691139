import { Box, Typography } from '@mui/material'
import React, { useState, useMemo, useEffect } from 'react'
import CustomTreeView from '../../threeview'
import { RootState } from '../../../../state/store'
import { useDispatch, useSelector } from 'react-redux'
import { useApiGetAccount, useApiGetFolders } from '../../../../hooks'
import {
  updateCurrentClientWorkspace,
  updateFolders,
  updateMapping,
  updateRootFolderId
} from '../../../../state/slices/invitation'

type Props = {
  review: boolean
}

const Folders = (props: Props) => {
  const { review } = props
  const dispatch = useDispatch()
  const { folders, currentWorkspace, rootFolderId, inviteSupplier, samePermissions, selectedWorkspaces } = useSelector(
    (state: RootState) => state.invitation
  )
  const [accountState, loadAccount] = useApiGetAccount(currentWorkspace?.account_uuid)
  const [folderList, loadFolders] = useApiGetFolders(currentWorkspace?.account_uuid, rootFolderId, folders)
  const [nextWorkspaceName, setNextWorkspaceName] = useState('')

  useEffect(() => {
    if (
      currentWorkspace &&
      currentWorkspace.account_uuid &&
      (!accountState.isLoaded || accountState.data?.data?.id !== currentWorkspace.account_uuid)
    )
      loadAccount({ include: 'folder', role: 'member,owner,employee' })
  }, [currentWorkspace])

  useEffect(() => {
    if (accountState.isLoaded && accountState.data?.data?.id === currentWorkspace.account_uuid) {
      dispatch(updateRootFolderId(accountState.data?.data?.folder?.data?.id))
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [accountState.isLoaded])

  useEffect(() => {
    if (folderList.isLoaded && folderList.data) {
      dispatch(updateFolders(folderList.data))
    }
  }, [folderList.isLoaded])

  useEffect(() => {
    if (review === true) {
      dispatch(
        updateMapping(
          inviteSupplier.mappings.map((workspace) => {
            if (workspace.account_uuid === currentWorkspace.account_uuid) {
              return { ...currentWorkspace }
            } else if (samePermissions === true) {
              return {
                ...workspace,
                permissions: currentWorkspace.permissions
              }
            }

            return workspace
          })
        )
      )
      const currentIndex = inviteSupplier.mappings.findIndex(
        (workspace) => workspace.account_uuid === currentWorkspace.account_uuid
      )
      if (inviteSupplier.mappings[currentIndex + 1]) {
        setNextWorkspaceName(
          selectedWorkspaces.find(
            (workspace) => workspace.id === inviteSupplier.mappings[currentIndex + 1].account_uuid
          ).name
        )
      } else {
        setNextWorkspaceName('')
      }
    }
  }, [review])

  const setSelectedFolders = (e: string[]) => {
    const workspace = {
      ...currentWorkspace,
      folders: e
    }

    dispatch(updateCurrentClientWorkspace(workspace))
    dispatch(
      updateMapping(
        inviteSupplier.mappings.map((myWorkspace) => {
          if (myWorkspace.account_uuid === currentWorkspace.account_uuid) {
            return workspace
          } else if (samePermissions === true) {
            return {
              ...myWorkspace,
              permissions: currentWorkspace.permissions
            }
          }

          return myWorkspace
        })
      )
    )
  }

  const renderFolders = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 2
        }}>
        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          Folders on {accountState.data?.data?.name}
        </Typography>
        <CustomTreeView
          title={`Folders on ${accountState.data?.data?.name}`}
          treeData={folders}
          selectedNodeArray={currentWorkspace?.folders || []}
          setSelectedNodeArray={setSelectedFolders}
          multiSelect={true}
        />
      </Box>
    )
  }, [folders, currentWorkspace, accountState.isLoaded])

  return (
    <>
      {review === true ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          alignContent={'center'}
          paddingTop={2}
          gap={2}>
          <Box>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <path
                d="M31.9987 5.33398C17.2787 5.33398 5.33203 17.2807 5.33203 32.0007C5.33203 46.7207 17.2787 58.6673 31.9987 58.6673C46.7187 58.6673 58.6654 46.7207 58.6654 32.0007C58.6654 17.2807 46.7187 5.33398 31.9987 5.33398ZM31.9987 53.334C20.2387 53.334 10.6654 43.7607 10.6654 32.0007C10.6654 20.2407 20.2387 10.6673 31.9987 10.6673C43.7587 10.6673 53.332 20.2407 53.332 32.0007C53.332 43.7607 43.7587 53.334 31.9987 53.334ZM44.2387 20.214L26.6654 37.7873L19.7587 30.9073L15.9987 34.6673L26.6654 45.334L47.9987 24.0007L44.2387 20.214Z"
                fill="#007AFF"
              />
            </svg>
          </Box>
          <Box>
            <Typography variant="h5">
              You have finished the Partnership Setup of {accountState.data?.data?.name}
            </Typography>
          </Box>
          {nextWorkspaceName && (
            <Box>
              <Typography variant="body1">We will start the setup of {nextWorkspaceName}. </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <>
          {folders && (
            <Box>
              <Box>
                <Box>
                  <Typography variant="h5">{`You are currently setting up ${accountState.data?.data?.name}`}</Typography>
                </Box>
              </Box>

              <Box>{renderFolders}</Box>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default Folders
