/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import React, { ReactNode, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ClientDataDetail from '../detail/partnerInvitation/clientData'
import CloseIcon from '@mui/icons-material/Close'
import CustomStepper from '../stepper'
import FolderMappingDetail from '../detail/partnerInvitation/folderMapping'
import { RootState } from '../../../state/store'
import SendInviteDetail from '../detail/partnerInvitation/sendInvite'
import { StepItem, STEPPER_ITEMS } from '../../../types'
import WorkspaceSetupDetail from '../detail/partnerInvitation/workspaceSetup'
import { resetState, updateInviteSupplier } from '../../../state/slices/invitation'
import { useApiPostInvite, useApiGetFindSubscriptions } from '../../../hooks'
import { useParams } from 'react-router-dom'
import { useSnackbar } from '../../providers/snackbar'
import ContactReviewDetail from '../detail/partnerInvitation/contactReview'
import { InvitationRequesterRole } from '../../../state/slices/types'

interface IDrawerInvitePartnersProps {
  isOpened: boolean
  setIsOpened: (state: boolean) => any
  children?: ReactNode
}

const DrawerInvitePartners: React.FC<IDrawerInvitePartnersProps> = ({
  isOpened,
  setIsOpened
}: IDrawerInvitePartnersProps) => {
  const dispatch = useDispatch()
  const { id: subscriptionId } = useParams<{ id: string }>()
  const [step, setStep] = useState(STEPPER_ITEMS.FOLDER_MAPPING)
  const [_, sendInvite] = useApiPostInvite(subscriptionId)
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const { showSnackbar } = useSnackbar()
  const [foundSubscriptions, findSubscriptions] = useApiGetFindSubscriptions(subscriptionId)

  const handleBackStep = () => handleChangeStep(-1)
  const handleNextStep = () => handleChangeStep(1)

  useEffect(() => {
    if (foundSubscriptions.data && foundSubscriptions.isLoaded) {
      const isExistentContact = foundSubscriptions.data.data ? foundSubscriptions.data.data.length > 0 : false

      if (isExistentContact) {
        setStep(STEPPER_ITEMS.CONTACT_REVIEW)
      } else {
        setStep(STEPPER_ITEMS.WORKSPACE_SETUP)
      }
    }
  }, [foundSubscriptions])

  const handleFinishInvitation = () => {
    setIsOpened(false)
    dispatch(resetState())
    setStep(STEPPER_ITEMS.FOLDER_MAPPING)
    window.dispatchEvent(new Event('@invites/GET_ALL'))
  }

  const handleSubmitInvitation = (subscription = null) => {
    try {
      const subscriptionUuid = subscription ? subscription.id : null
      const as = subscription ? InvitationRequesterRole.SupplierExisting : InvitationRequesterRole.SupplierNew

      updateInviteSupplier({ key: 'subscription_uuid', value: subscriptionUuid })
      updateInviteSupplier({ key: 'as', value: as })

      const inviteSupplierData = { ...inviteSupplier, as: as, subscription_uuid: subscriptionUuid }

      sendInvite(inviteSupplierData)
      setStep(STEPPER_ITEMS.SEND_INVITE)
    } catch (err) {
      showSnackbar(err.message, 'error')
    }
  }

  const handleReviewContact = async () => {
    /**
     * @TODO DO ANYTHING NEEDED TO GET CONTACT INFORMATION
     * IF EXISTS, PUT ON STORE AND FILL "contactReview.tsx" COMPONENT WITH DATA
     */
    findSubscriptions(inviteSupplier.contact.email)
  }

  const steps = useMemo<StepItem[]>(() => {
    return [
      {
        label: 'Folder Mapping',
        value: STEPPER_ITEMS.FOLDER_MAPPING,
        component: <FolderMappingDetail />,
        description:
          "You can map the client's workspace to one of your existing workspaces, allowing you to use your configuration tools, such as Tasks and Certificates, within the client's workspace. This step is optional.",
        controls: {
          back: {
            hidded: true
          },
          next: {
            disabled: !inviteSupplier.folder_uuid,
            onClick: handleNextStep
          }
        }
      },
      {
        label: 'Client Data',
        value: STEPPER_ITEMS.CLIENT_DATA,
        component: <ClientDataDetail />,
        description:
          "Enter your client's information to send a partnership invitation. They will be notified once you have extended an invitation to them. You can modify this information later if necessary. Fields marked with * are mandatory.",
        controls: {
          back: {
            onClick: handleBackStep
          },
          next: {
            disabled:
              !inviteSupplier.contact ||
              !inviteSupplier.contact.first_name.length ||
              !inviteSupplier.contact.last_name.length ||
              !inviteSupplier.contact.email.length ||
              !inviteSupplier.contact.phone_number.length ||
              !inviteSupplier.contact.country_code ||
              !inviteSupplier.company_name.length ||
              !inviteSupplier.industry_uuid,
            onClick: handleReviewContact
          }
        }
      },
      {
        label: 'Contact Review',
        value: STEPPER_ITEMS.CONTACT_REVIEW,
        hidden: true,
        component: (
          <ContactReviewDetail foundSubscriptions={foundSubscriptions} onFinishInvitation={handleSubmitInvitation} />
        ),
        description: 'We have identified existing Papertrail Customers from the contact information provided.',
        controls: {
          back: {
            onClick: handleBackStep
          },
          next: {
            hidded: true
          }
        }
      },
      {
        label: 'Workspace Setup',
        value: STEPPER_ITEMS.WORKSPACE_SETUP,
        component: <WorkspaceSetupDetail />,
        description:
          "Set up your new client's Workspace by defining its name and selecting the Module they will use to interact with you. This will be a shared Workspace between you and your Partner.",
        controls: {
          back: {
            onClick: () => {
              updateInviteSupplier({ key: 'subscription_uuid', value: null })
              setStep(STEPPER_ITEMS.CLIENT_DATA)
            }
          },
          next: {
            disabled: !inviteSupplier.account_name?.length && !inviteSupplier.modules?.length,
            onClick: () => handleSubmitInvitation(null)
          }
        }
      },
      {
        label: 'Send Invite',
        value: STEPPER_ITEMS.SEND_INVITE,
        component: <SendInviteDetail />,
        description: "Your invitation has been sent. You can check the status in the 'Sent' tab in the Partners Page.",
        controls: {
          back: {
            hidded: true
          },
          next: {
            text: 'Close',
            onClick: handleFinishInvitation
          }
        }
      }
    ]
  }, [inviteSupplier, foundSubscriptions])

  const selectedStep = useMemo(() => {
    return steps.find((item) => item.value === step)
  }, [steps, step])

  const handleChangeStep = (nextStep: number) => {
    const nextStepIndex = step + nextStep
    if (nextStepIndex < 0 || nextStepIndex > steps.length) return false
    setStep((currentStep) => currentStep + nextStep)
  }

  const handleCloseDrawer = () => {
    dispatch(resetState())
    setIsOpened(false)
    setStep(STEPPER_ITEMS.FOLDER_MAPPING)
  }

  return (
    <>
      <Drawer anchor="right" open={isOpened} onClose={handleCloseDrawer}>
        <Box sx={{ maxWidth: '100vw', p: '5%', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34
            }}>
            <Typography variant="h5">Invite New Client</Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6">{selectedStep.label}</Typography>
              <Typography variant="body1">{selectedStep.description}</Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <CustomStepper items={steps} activeStep={step} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                {!selectedStep.controls.back.hidded && (
                  <Button
                    disabled={selectedStep.controls.back.disabled}
                    variant="outlined"
                    onClick={selectedStep.controls.back.onClick}>
                    {selectedStep.controls.back.text || 'Back'}
                  </Button>
                )}
                {!selectedStep.controls.next.hidded && (
                  <Button
                    disabled={selectedStep.controls.next.disabled}
                    variant="contained"
                    color="primary"
                    onClick={selectedStep.controls.next.onClick}>
                    {selectedStep.controls.next.text || 'Next'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          {selectedStep.component}
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerInvitePartners
