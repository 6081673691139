import { Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography, stepConnectorClasses } from '@mui/material'

import { Check } from '@mui/icons-material'
import React from 'react'
import { StepItem } from 'src/types'

interface CustomStepperProps {
  items: StepItem[]
  activeStep: number
}

const CustomConnector = (props: any) => (
  <StepConnector
    {...props}
    sx={{
      [`&.${stepConnectorClasses.line}`]: {
        borderColor: 'divider',
        borderTopWidth: 3,
        borderRadius: 1,
        flexGrow: 1
      }
    }}
  />
)

const CustomStepIcon: React.FC<{ completed: boolean; active: boolean; icon: React.ReactNode }> = ({
  completed,
  active,
  icon
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: completed ? '#007AFF' : '#fff',
      backgroundColor: completed || active ? '#007AFF' : '#9e9e9e',
      borderRadius: '50%',
      width: 24,
      height: 24
    }}>
    {completed ? <Check style={{ color: 'white' }} /> : icon}
  </div>
)

const CustomStepper: React.FC<CustomStepperProps> = ({ items, activeStep }) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
      sx={{ width: '100%', flexWrap: 'wrap', padding: '0 16px' }}>
      {items
        .filter(({ hidden }) => !hidden)
        .map((item) => {
          const isCompleted = item.value < activeStep
          const isActive = item.value === activeStep
          return (
            <Step key={item.value} completed={isCompleted}>
              <StepLabel
                StepIconComponent={(props: StepIconProps) => (
                  <CustomStepIcon completed={isCompleted} active={isActive} icon={props.icon} />
                )}>
                <Typography variant="subtitle2">{item.label}</Typography>
              </StepLabel>
            </Step>
          )
        })}
    </Stepper>
  )
}

export default CustomStepper
