import React, { useEffect, useMemo, useState } from 'react'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import { Stack, Box } from '@mui/material'
import CustomPlaceholder from '../../../molecules/placeholder'
import InviteGrid from '../../grid/invite'
import PartnershipGrid from '../../grid/partnership'
import { useApiGet } from '@papertrail/web3-utils'
import { useParams, useLocation } from 'react-router-dom'
import { ManagerGridTypes } from '../../../../types'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../state/slices/rootReducer'
import { setInvitation } from '../../../../state/slices/invitation'

type Params = {
  id: string
}

interface ManagerGridProps {
  drawerOpen?: () => void
  filterType: ManagerGridTypes
}

export const invitationMapper = (data, subscriptionId: string, filterType: ManagerGridTypes) => {
  if (filterType === ManagerGridTypes.ACTIVE) return { data: data.data, meta: data.meta }

  return {
    data: data.data
      .filter((invitation) => {
        if (filterType === ManagerGridTypes.SENT) {
          return invitation.consumer_contact.subscription_id !== subscriptionId
        } else {
          return invitation.consumer_contact.subscription_id === subscriptionId
        }
      })
      .map((invitation) => ({
        ...invitation,
        consumerAccounts: invitation.consumerAccounts?.data || undefined
      })),
    meta: data.meta
  }
}

const ManagerInviteGrid: React.FC<ManagerGridProps> = ({ drawerOpen, filterType }) => {
  const params = useParams<Params>()
  const subscriptionId = params?.id
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const inviteParam = queryParams.get('invite')

  const { subscription } = useSelector((state: RootState) => state.global)
  const [myPagination, setMyPagination] = useState<{ page: number; limit: number; status?: ManagerGridTypes }>({
    page: 1,
    limit: 10
  })

  const getInvitationPath = useMemo(() => {
    // invite tabs
    if (filterType === ManagerGridTypes.SENT || filterType === ManagerGridTypes.RECEIVED) return `/invite`

    // partnertship tabs
    setMyPagination({ ...myPagination, status: filterType })
    return ''
  }, [filterType])

  const [InvitationState, getInvitations] = useApiGet<any>(
    `/partnerhub/${subscriptionId}/partnerships${getInvitationPath}`,
    (data) => invitationMapper(data, subscriptionId, filterType) // Pass the filterType to the mapper
  )

  const handleGetInitialInvitations = () => {
    getInvitations(myPagination)
  }

  useEffect(() => {
    if (myPagination) {
      getInvitations(myPagination)
    }
  }, [myPagination])

  const setPaginationModel = (pagination) => {
    if (pagination && pagination.page !== myPagination.page + 1) {
      setMyPagination({ page: pagination.page + 1, limit: 10 })
    }
  }

  useEffect(() => {
    handleGetInitialInvitations()

    window.addEventListener('@invites/GET_ALL', handleGetInitialInvitations)

    return () => {
      window.removeEventListener('@invites/GET_ALL', handleGetInitialInvitations)
    }
  }, [])

  useEffect(() => {
    if (inviteParam && InvitationState.isLoaded && InvitationState.data.data.length) {
      const invitation = InvitationState.data.data.find((invite) => invite.id === inviteParam)
      dispatch(setInvitation(invitation))
    }
  }, [inviteParam, InvitationState.isLoaded])

  return (
    <Stack height="60vh" overflow={'auto'}>
      {InvitationState.isLoaded && !InvitationState?.data?.data?.length && !InvitationState?.data.length && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CustomPlaceholder
            IconComponent={AddBusinessIcon}
            title={`You currently have no ${filterType} partner invitations.`}
            description={
              filterType === ManagerGridTypes.SENT
                ? 'If you receive an invitation, you will see it in the ”Received” Tab.'
                : 'If you send an invitation, you will see it in the ”Sent” Tab. '
            }
            buttonText="Invite New Partner"
            onClick={drawerOpen}
            hideButton={!subscription?.supplier}
          />
        </Box>
      )}

      {InvitationState.isLoaded && InvitationState?.data?.data?.length > 0 && (
        <>
          {[ManagerGridTypes.SENT, ManagerGridTypes.RECEIVED].includes(filterType) && (
            <InviteGrid data={InvitationState.data} setPaginationModel={setPaginationModel} type={filterType} />
          )}
          {[ManagerGridTypes.ACTIVE, ManagerGridTypes.INACTIVE].includes(filterType) && (
            <PartnershipGrid data={InvitationState.data} setPaginationModel={setPaginationModel} />
          )}
        </>
      )}
    </Stack>
  )
}

export default ManagerInviteGrid
