import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'

interface TabOptions {
  label: string
  value: string
  description?: string
}

type Props = {
  options: TabOptions[]
  onTabChange: (newValue: string) => void
  defaultValue?: string
  disabledValues?: string[]
}

const CustomTabs = (props: Props) => {
  const { options, onTabChange, defaultValue, disabledValues } = props
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event, newValue: string) => {
    setValue(newValue)
    onTabChange(newValue)
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Tabs value={value} onChange={handleChange} aria-label="custom tabs">
      {options.map(({ label, value }) => (
        <Tab key={value} disabled={disabledValues && disabledValues.includes(value)} value={value} label={label} />
      ))}
    </Tabs>
  )
}

export default CustomTabs
