import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { useState, useMemo, useEffect } from 'react'
import CustomTabs from '../../../molecules/tab'
import CustomTreeView from '../../threeview'
import { RootState } from '../../../../state/store'
import { useSelector } from 'react-redux'

const PartnerPermissions = () => {
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const [workspaceId, setWorkspaceId] = useState('')
  const [currentWorkspace, setCurrentWorkspace] = useState(null)
  const [currentMappings, setCurrentMappings] = useState(null)
  const TAB_STATES = [
    { value: 'FOLDERS', label: 'Folder Access Permissions' },
    { value: 'PERMISSIONS', label: 'Partner Permissions' }
  ]
  const handleWorkspaceChange = (event: SelectChangeEvent<any>) => {
    setWorkspaceId(event.target.value)
  }
  const [currentTab, setCurrentTab] = useState('FOLDERS')

  useEffect(() => {
    if (workspaceId) {
      setCurrentWorkspace(inviteSupplier.consumerAccounts.find((account) => account.id === workspaceId))
      setCurrentMappings(inviteSupplier.mappings.find((mapping) => mapping.account_uuid === workspaceId))
    }
  }, [workspaceId])

  const mapFolders = (folderData) => {
    return {
      id: folderData.id,
      name: folderData.name,
      children:
        folderData.children?.data && folderData.children.data.length > 0
          ? folderData.children.data.map(mapFolders) // Recursively map each child folder
          : undefined // Omit 'children' if there are no child folders
    }
  }

  const renderFolders = useMemo(() => {
    if (currentMappings && currentWorkspace) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginTop: 2
          }}>
          <Typography
            variant="body1"
            sx={{
              color: 'rgba(0, 0, 0, 0.87)'
            }}>
            Folders on {}
          </Typography>
          <CustomTreeView
            title={`Folders on ${inviteSupplier.consumerAccounts.find((account) => account.id === workspaceId).name}`}
            treeData={[mapFolders(currentWorkspace?.folder.data)]}
            selectedNodeArray={currentMappings?.folders || []}
            multiSelect={true}
            readOnly
          />
        </Box>
      )
    }
  }, [currentWorkspace, currentMappings])

  if (inviteSupplier && inviteSupplier.id) {
    return (
      <>
        <Box>
          <Box>
            <Typography variant="h5">{`You are viewing the Folder Access Permissions granted by ${inviteSupplier.company_name}.`}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body1">Select an account to view its Partner Permissions:</Typography>
        </Box>
        <Box>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Workspace</InputLabel>
            <Select
              sx={{ width: '220px' }}
              value={workspaceId}
              label="Workspace"
              placeholder="Workspace"
              onChange={handleWorkspaceChange}>
              {inviteSupplier.consumerAccounts?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box paddingTop="24px">
          <CustomTabs
            options={TAB_STATES}
            onTabChange={(newValue: string) => {
              setCurrentTab(newValue)
            }}
            defaultValue={currentTab}></CustomTabs>
        </Box>
        {currentTab === 'FOLDERS' && currentWorkspace && currentMappings && <Box>{renderFolders}</Box>}
        {currentTab === 'PERMISSIONS' && currentWorkspace && currentMappings && (
          <Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Folder Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentMappings?.permissions?.folders}
                  disabled
                  label="Folder Permissions"
                  placeholder=""
                  onChange={null}>
                  <MenuItem key={currentMappings?.folders} value={currentMappings?.permissions?.folders}>
                    {currentMappings?.permissions?.folders}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Record Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentMappings?.permissions?.records}
                  disabled
                  label="Record Permissions"
                  placeholder=""
                  onChange={null}>
                  <MenuItem key={currentMappings?.permissions?.records} value={currentMappings?.permissions?.records}>
                    {currentMappings?.permissions?.records}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Inspection Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentMappings?.permissions?.logs}
                  disabled
                  label="Inspection Permissions"
                  placeholder=""
                  onChange={null}>
                  <MenuItem key={currentMappings?.permissions?.logs} value={currentMappings?.permissions?.logs}>
                    {currentMappings?.permissions?.logs}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Task Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentMappings?.permissions?.tasks}
                  disabled
                  label="Task Permissions"
                  placeholder=""
                  onChange={null}>
                  <MenuItem key={currentMappings?.permissions?.tasks} value={currentMappings?.permissions?.tasks}>
                    {currentMappings?.permissions?.tasks}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </>
    )
  }
}

export default PartnerPermissions
