import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FieldArray, Form } from 'formik'
import { RootState } from 'src/state/store'
import { useApiGetAccountStates } from '../../../../hooks'
import { updateMappingState } from '../../../../state/slices/invitation'

const MapStates = () => {
  const dispatch = useDispatch()
  const { workspaceId, inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const [accountStatesState, loadAccountStates] = useApiGetAccountStates(workspaceId)

  // Track selected values for each account
  const [selectedValuesByAccount, setSelectedValuesByAccount] = useState<{ [key: number]: string[] }>({})

  useEffect(() => {
    if (workspaceId) {
      loadAccountStates({})
    }
  }, [workspaceId])

  const matchSupplierState = (name: string) => {
    const matchedState = accountStatesState.data?.find((state) => name === state.label)
    return matchedState ? matchedState.value : 'null'
  }

  const initialValues =
    inviteSupplier && accountStatesState.data
      ? {
          accounts: inviteSupplier.consumerAccounts.map((item) => ({
            account: item.name,
            states: item.states.data.map((state) => ({
              consumer_state_uuid: state.id,
              host_state_uuid: matchSupplierState(state.name) || ''
            }))
          }))
        }
      : { accounts: [] }

  useEffect(() => {
    if (initialValues && !inviteSupplier.mappings[0].states.length) {
      initialValues.accounts.forEach((account) => {
        const updatedStates = account.states.map((state) =>
          state.host_state_uuid === 'null' ? { ...state, host_state_uuid: '' } : state
        )

        dispatch(
          updateMappingState({
            account_uuid: inviteSupplier.consumerAccounts.find((consAcc) => consAcc.name === account.account).id,
            newState: updatedStates
          })
        )
      })

      const initialSelectedValues = initialValues.accounts.map((account) =>
        account.states.map((state) => state.host_state_uuid).filter((val) => val !== 'null' && val !== '')
      )

      const formattedValues = initialSelectedValues.reduce((acc, selectedValues, index) => {
        acc[index] = selectedValues
        return acc
      }, {})

      setSelectedValuesByAccount(formattedValues)
    }
  }, [initialValues, inviteSupplier])

  const handleFormikChange = (formikProps, accountIndex) => {
    const accountSelected = formikProps.values.accounts[accountIndex].states
      .map((state) => state.host_state_uuid)
      .filter((val) => val !== 'null' && val !== '')

    setSelectedValuesByAccount((prev) => ({
      ...prev,
      [accountIndex]: accountSelected
    }))

    formikProps.values.accounts.forEach((account, index) => {
      const updatedStates = account.states.map((state) =>
        state.host_state_uuid === 'null' ? { ...state, host_state_uuid: '' } : state
      )

      const accountUuid = inviteSupplier.consumerAccounts.find((consAcc) => consAcc.name === account.account).id

      dispatch(
        updateMappingState({
          account_uuid: accountUuid,
          newState: updatedStates
        })
      )
    })
  }

  const findStateName = (accountIndex, stateIndex) => {
    return inviteSupplier.consumerAccounts[accountIndex].states.data[stateIndex].name
  }

  const filterOptions = (options, selectedValues, currentSelected) => {
    return options.filter((option) => option.value === currentSelected || !selectedValues.includes(option.value))
  }

  if (accountStatesState.data && accountStatesState.data.length > 0 && initialValues) {
    return (
      <div>
        <Formik initialValues={initialValues} onSubmit={null}>
          {(formikProps) => (
            <Form>
              <FieldArray name="accounts">
                {() => (
                  <>
                    {formikProps.values.accounts.map((account, accountIndex) => (
                      <Accordion key={accountIndex} defaultExpanded={accountIndex === 0}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${accountIndex}-content`}
                          id={`panel${accountIndex}-header`}>
                          <Typography variant="h6">{account.account}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box flex={1}>
                              <Typography variant="body1">Client States</Typography>
                            </Box>
                            <Box flex={1} marginLeft={'41px'}>
                              <Typography variant="body1">Your States</Typography>
                            </Box>
                          </Box>
                          <FieldArray name={`accounts.${accountIndex}.states`}>
                            {() => (
                              <div>
                                {account.states.map((state, stateIndex) => (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems={'center'}
                                    key={stateIndex}
                                    marginTop="20px"
                                    gap={'16px'}>
                                    <TextField
                                      disabled
                                      value={findStateName(accountIndex, stateIndex)}
                                      label="Client State"
                                      variant="outlined"
                                      fullWidth
                                    />
                                    <Box>
                                      <ArrowForwardIcon />
                                    </Box>

                                    <TextField
                                      select
                                      name={`accounts.${accountIndex}.states.${stateIndex}.host_state_uuid`}
                                      value={
                                        formikProps.values.accounts[accountIndex].states[stateIndex].host_state_uuid
                                      }
                                      onChange={(e) => {
                                        formikProps.setFieldValue(
                                          `accounts.${accountIndex}.states.${stateIndex}.host_state_uuid`,
                                          e.target.value
                                        )
                                        formikProps.handleChange(e)
                                        handleFormikChange(formikProps, accountIndex)
                                      }}
                                      label="Your State"
                                      variant="outlined"
                                      fullWidth>
                                      {filterOptions(
                                        accountStatesState.data,
                                        selectedValuesByAccount[accountIndex] || [],
                                        formikProps.values.accounts[accountIndex].states[stateIndex].host_state_uuid
                                      ).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Box>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    )
  } else {
    return null
  }
}

export default MapStates
