import { Box, Button, SvgIconTypeMap, Typography } from '@mui/material'

import { OverridableComponent } from '@mui/material/OverridableComponent'
import React from 'react'

interface CustomPlaceholderProps {
  title: string
  description: string
  buttonText: string
  IconComponent: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  hideButton?: boolean
  onClick?: () => void
}

const CustomPlaceholder = ({
  title,
  description,
  buttonText,
  hideButton,
  IconComponent,
  onClick
}: CustomPlaceholderProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'16px'}
      flex={'1 0 0'}
      alignSelf={'stretch'}
      padding={'0px 400px'}>
      <IconComponent
        style={{
          height: '64px',
          width: '64px',
          color: '#007AFF'
        }}
      />
      <Typography variant="h5" align="center">
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {description}
      </Typography>
      {!hideButton && <Button variant="contained" children={buttonText} onClick={onClick} />}
    </Box>
  )
}

export default CustomPlaceholder
