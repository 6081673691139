import { Box, DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@papertrail/styleguide'
import CustomChip from '../../atoms/chip'
import React, { useMemo } from 'react'
import { setInvitation } from '../../../state/slices/invitation'
import { useDispatch } from 'react-redux'
import { ManagerGridTypes } from '../../../types'

type Props = {
  data
  setPaginationModel
  type: ManagerGridTypes
}

const InviteGrid = (props: Props) => {
  const { data, setPaginationModel, type } = props
  const pagination = { page: data.meta.pagination.current_page - 1, pageSize: 10 }
  const dispatch = useDispatch()
  const formattedData = useMemo(() => {
    return data?.data?.map((invite) => ({
      id: invite.id,
      Name: type === ManagerGridTypes.SENT ? invite.company_name : invite?.host_contact?.company_name,
      Status:
        type === ManagerGridTypes.RECEIVED && invite.status === ManagerGridTypes.SENT
          ? ManagerGridTypes.RECEIVED
          : invite.status,
      'Last Updated': invite.updated_at,
      'Contact Name':
        type === ManagerGridTypes.RECEIVED
          ? invite.host_contact.first_name + ' ' + invite.host_contact.last_name
          : invite.consumer_contact.first_name + ' ' + invite.consumer_contact.last_name,
      'Contact Email': type === ManagerGridTypes.RECEIVED ? invite.host_contact.email : invite.consumer_contact.email,
      'Updated By': invite.updated_by.first_name + ' ' + invite.updated_by.last_name
    }))
  }, [data])

  const renderHeader = (params) => {
    const cellStyle = {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(0, 0, 0, 0.87)'
    }

    return (
      <div data-testid={params.field} style={cellStyle}>
        {params.field}
      </div>
    )
  }

  const getColor = (value) => {
    switch (value) {
      case ManagerGridTypes.SENT:
        return '#007AFF'
      default:
        return '#00A354'
    }
  }

  const renderCell = (params) => {
    const cellStyle = {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '400',
      color: 'rgba(0, 0, 0, 0.87)',
      cursor: 'pointer'
    }

    if (params.field === 'Status') {
      return <CustomChip status={params.value} color={getColor(params.value)} />
    }
    if (params.field === 'Last Updated') {
      return (
        <div data-testid={params.value} style={cellStyle}>
          {formatDate(params.value)}
        </div>
      )
    }

    return (
      <div data-testid={params.value} style={cellStyle}>
        {params.value}
      </div>
    )
  }

  const formatDate = (dateString) => {
    // Create a new Date object from the input string
    const date = new Date(dateString)

    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const year = date.getFullYear()

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`
  }

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ padding: '0 10px', fontFamily: 'Poppins' }}>
          <GridToolbarFilterButton />
        </Box>
      </GridToolbarContainer>
    )
  }

  const onInviteClicked = (e) => {
    dispatch(setInvitation(data.data.find((item) => item.id === e)))
  }

  const getFlexRatio = (field) => {
    switch (field) {
      case 'Name':
      case 'Contact Email':
        return 1

      case 'Status':
        return 0.4

      default:
        return 0.6
    }
  }

  return (
    <Box padding={2}>
      <DataGrid
        loading={false}
        meta={{
          meta: data.meta,
          pagination: pagination
        }}
        rowHeight={52}
        initialData={formattedData}
        columns={Object.keys(formattedData[0]).map((field, index) => ({
          field: field,
          flex: getFlexRatio(field),
          headerAlign: 'left',
          align: 'left',
          renderHeader: (params) => renderHeader(params),
          renderCell: (params) => renderCell(params),
          renderEditCell: (params) => renderCell(params)
        }))}
        filters={null}
        check={false}
        pinnedColumns={{ left: [] }}
        setPaginationModel={setPaginationModel}
        onRowClicked={onInviteClicked}
        customToolbar={customToolbar}
        customSlotProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontFamily: 'Poppins'
                    }
                  }
                }
              }
            },
            sx: {
              '& .MuiTablePagination-selectLabel': {
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.6)',
                fontFamily: 'Poppins'
              },
              '& .MuiTablePagination-select': {
                fontSize: '12px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)',
                lineHeight: 1,
                paddingTop: 1.5
              },
              '& .MuiTablePagination-displayedRows': {
                fontSize: '12px',
                fontFamily: 'Poppins',
                color: 'rgba(0, 0, 0, 0.87)'
              }
            }
          }
        }}></DataGrid>
    </Box>
  )
}

export default InviteGrid
