import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import { InviteSupplierData, SupplierContact } from '../../../../state/slices/types'
import React, { useEffect } from 'react'
import {
  updateCountries,
  updateIndustries,
  updateInviteSupplier,
  updateInviteSupplierContact
} from '../../../../state/slices/invitation'
import { useCountriesApi, useIndustriesApi } from '../../../../hooks'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../../state/store'

const ClientDataDetail: React.FC = () => {
  const maxCommentsLength = 200

  const dispatch = useDispatch()
  const { inviteSupplier, countries, industries } = useSelector((state: RootState) => state.invitation)

  const [newCountries, loadCountries] = useCountriesApi()
  const [newIndustries, loadIndustries] = useIndustriesApi()

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    dispatch(updateInviteSupplierContact({ key: 'country_code', value: event.target.value }))
  }

  const handleIndustryChange = (event: SelectChangeEvent<string>) => {
    dispatch(updateInviteSupplier({ key: 'industry_uuid', value: event.target.value }))
  }

  const handleInviteInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateInviteSupplier({ key: event.target.name as keyof InviteSupplierData, value: event.target.value }))
  }

  const handleContactInviteInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateInviteSupplierContact({ key: event.target.name as keyof SupplierContact, value: event.target.value })
    )
  }

  useEffect(() => {
    if (!countries.length && !industries.length) {
      loadIndustries({})
      loadCountries({})
    }
  }, [])

  useEffect(() => {
    if (newCountries.isLoaded && newIndustries.isLoaded) {
      dispatch(updateCountries(newCountries.data))
      dispatch(updateIndustries(newIndustries.data))
    }
  }, [newCountries, newIndustries])

  return (
    <Box component="form" sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            Client Information:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="first_name"
                label="Contact Name"
                required
                variant="outlined"
                value={inviteSupplier.contact.first_name}
                onChange={handleContactInviteInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="last_name"
                label="Client Last Name"
                required
                variant="outlined"
                value={inviteSupplier.contact.last_name}
                onChange={handleContactInviteInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Contact Email Address"
                required
                type="email"
                variant="outlined"
                value={inviteSupplier.contact.email}
                onChange={handleContactInviteInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="phone_number"
                label="Contact Phone Number"
                required
                type="tel"
                variant="outlined"
                value={inviteSupplier.contact.phone_number}
                onChange={handleContactInviteInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Country</InputLabel>
                <Select value={inviteSupplier.contact.country_code} onChange={handleCountryChange} label="Country">
                  {countries.map((country) => (
                    <MenuItem value={country.value}>{country.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            Client's Company Information:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="company_name"
                label="Client's Company Name"
                required
                variant="outlined"
                value={inviteSupplier.company_name}
                onChange={handleInviteInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Client's Company Industry</InputLabel>
                <Select
                  value={inviteSupplier.industry_uuid}
                  onChange={handleIndustryChange}
                  label="Client's Company Industry">
                  {industries.map((industry) => (
                    <MenuItem value={industry.value}>{industry.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                variant="outlined"
                name="comments"
                multiline
                rows={4.1}
                value={inviteSupplier.comments}
                onChange={handleInviteInputChange}
                helperText={`${inviteSupplier.comments.length}/${maxCommentsLength}`}
                inputProps={{ maxLength: maxCommentsLength }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientDataDetail
