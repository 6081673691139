import { combineReducers } from 'redux'
import globalReducer from './global'
import invitationReducer from './invitation'

const rootReducer = combineReducers({
  invitation: invitationReducer,
  global: globalReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
